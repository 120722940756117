@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');


  /* Typewriter effect */
@keyframes typewriter {
  from { width: 0; }
  to { width: 9em; }
}

/* Cursor animations */
@keyframes blinkCursor {
  from { border-right-color: rgba(255,255,255,.75); }
  to { border-right-color: transparent; }
}

.typewriter h1 {
  font-family: 'Orbitron', sans-serif;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em; /* Adjust as needed */
  animation: 
    typewriter 0.75s steps(10),
    blinkCursor .75s step-end 4;
    color: #ffffff;
}

/* Basic reset for body margin and padding */
body, html {
  margin: 0;
  padding: 0;
}

/* Hero section styles */
/* Adjusted hero section styles */
.hero-section {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 40%;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  color: white;
  text-align: left;
  letter-spacing: 0.01cm;
}

.hero-headline {
  font-size: 2.5em;
  overflow: visible; /* Changed to visible to ensure the span doesn't cut off */
  white-space: normal; /* Changed to normal to allow line breaks */
  color: #ffffff;
  z-index: 2;
}

@media (max-width: 1500px) {
  .hero-headline {
    font-size: 2.1em;
  }
}


.hero-subheading {
  font-size: 1.1em;
  overflow: visible; /* Changed to visible to ensure the span doesn't cut off */
  white-space: normal; /* Changed to normal to allow line breaks */
  color: #ffffff;
  letter-spacing: 0.01cm;
  width: 70%;
}

@media (max-width: 768px) {
  .hero-subheading {
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
    width: 80%
  }
}

.typewriter-text {
  display: inline-block; /* Allows the span to be treated as an individual block for animation */
  overflow: hidden;
  white-space: nowrap;
  animation: typewriterTwo 0.75s steps(15) 1s forwards, /* Adjust steps based on the length of "Ultimate" */
            blinkCursor 750ms step-end infinite;
  position: relative;
  top: 5px;
  color: rgb(255, 255, 255);
  width: 0; 
  font-weight: 900;
}


.typewriter-text.typewriter-learn {
  animation: typewriterLearn 0.75s steps(15) 1s forwards, blinkCursor 750ms step-end infinite;
}

.typewriter-text.typewriter-scribe {
  animation: typewriterScribe 0.75s steps(15) 1s forwards, blinkCursor 750ms step-end infinite;
}

.typewriter-text.typewriter-qa {
  animation: typewriterQA 0.75s steps(15) 1s forwards, blinkCursor 750ms step-end infinite;
}

/* Keyframes for 'Learn' */
@keyframes typewriterLearn {
  from { width: 0; }
  to { width: 68%; }
}

/* Keyframes for 'Scribe' */
@keyframes typewriterScribe {
  from { width: 0; }
  to { width: 58%; }
}

/* Keyframes for 'Q+A' */
@keyframes typewriterQA {
  from { width: 0; }
  to { width: 64%; }
}

.navbar {
  display: flex;
  justify-content: flex-end; /* Align items to the right */
  background: rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  border: white;
  padding: 0px 0px;
  margin: 0 auto;
  margin-top: 1%;
  width: fit-content;
  z-index: 1000;
}

.navbar button {
  background: transparent;
  border: white;
  color: white;
  font-size: 16px;
  margin: 0 10px;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 10px;
  transition: background 0.3s ease;
  z-index: 1000;
  animation: pulse 2s infinite;
}

.navbar button:hover {
  background: rgba(255, 255, 255, 0.2);
}

.fade-in {
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}


/* Styles for side images */
.side-image {
  opacity: 0.6; /* Faded appearance */
  border-radius: 10px; /* Rounded borders */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* More pronounced elevated appearance */
  margin: 0 10px; /* Spacing between images */
}

/* Styles for the main image */
.main-image {
  animation: fadeIn ease 2s;
  /* No border-radius or box-shadow for the main image to keep it normal */
}

.arrow-text {
  white-space: nowrap;
  margin-left: 8px;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animated-lower {
  animation: lower 1s forwards;
}

@keyframes lower {
  from { transform: translateY(0); }
  to { transform: translateY(43%); } /* Adjust the value as needed */
}

@keyframes fadeInScale {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeInScale {
  animation: fadeInScale 2s forwards;
  width: 175%;
  height: auto;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%); /* Adjust based on the center */
  z-index: -1;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.pulse {
  animation: pulse 2s infinite;
}

/* Add this to your Homepage.css file */
.highlighted-word {
  background-color: white;
  color: rgba(40, 154, 166, 1.0);
  padding: 0 5px; /* Adjust padding as needed */
  border-radius: 5px; /* Optional: adds rounded corners to the background */
}

/* Ensure .hero-headline is styled to allow for inline or inline-block elements */
.hero-headline {
  /* Your existing styles */
  display: inline; /* or inline-block, depending on your layout */
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.9;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Mobile view */
@media (max-width: 768px) {
  .navbar .hamburger-menu {
    display: block; /* Ensure this is visible */
    cursor: pointer;
  }
  .navbar .menu-dropdown {
    display: none; /* Hide dropdown by default */
    position: absolute;
    right: 0;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  }
  .navbar .menu-dropdown.show {
    display: block; /* Show dropdown when menu is open */
  }
  .homepage-container > button { /* Target buttons directly within homepage-container */
    display: none; /* Hide buttons in navbar and the direct children buttons of homepage-container */
  }
}

/* Desktop view */
@media (min-width: 769px) {
  .hamburger-menu {
    display: none; /* Hide hamburger icon */
  }
  .menu-dropdown {
    display: none; /* Ensure dropdown is also hidden */
  }
  .navbar button {
    display: inline-block; /* Show buttons in navbar */
  }
}

.menu-dropdown {
  width: 70%;
  position: fixed;
  top: 0;
  right: -70%; /* Start hidden off-screen */
  height: 100%;
  background-color: white;
  z-index: 1000; /* Ensure it's above other content */
  /* Add transition for smooth closing */
  transition: right 0.3s;
}

.menu-dropdown.show {
  right: 0; /* Slide in */
  animation: slideIn 0.3s forwards; /* Use the defined animation */
}


@keyframes slideIn {
  from {
    right: -70%;
  }
  to {
    right: 0;
  }
}

.menu-dropdown .close-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media (max-width: 768px) { /* Adjust 768px to your mobile breakpoint */
  .fade-out {
    animation: fadeOut 1s forwards;
  }
}

@media (max-width: 768px) {
  .hero-section {
    position: absolute;
    top: 90%; /* Adjust this value as needed to position the hero section below the caption text */
    left: 50%;
    transform: translateX(-50%);
    width: 90%; /* Adjust the width as needed */
    text-align: center; /* Center the content */
  }
  
}

/* Add this to your Homepage.css or relevant CSS file */
/* Homepage.css */
.SP-div-style {
  width: 50%;
  margin: 20px auto;
  background: white;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  padding-bottom: 40px;
  color: white;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.SP1-div-style {
  width: 50%;
  margin: 20px auto;
  background: white;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  padding-bottom: 40px;
  color: white;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (max-width: 768px) {
  .SP-div-style {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .SP1-div-style {
    width: 90%;
  }
}
/* Homepage.css */
.security-info {
  font-family: Arial;
  font-size: 18px;
  color: black;
  margin-top: -80px;
  margin-bottom: 120px;
  background: transparent;
  text-align: justify;
  margin-left: 20px;
  margin-right: 20px;
}

@media (max-width: 768px) {
  .security-info {
    margin-left: 5%;
    margin-right: 5%;
  }
}

.flex-center {
  display: flex;
  align-items: center;
}

.iphone-image {
  scale: 50%;
  margin: 5px 0;
  margin-left: 40px;
}

.text-container {
  font-family: Arial;
  font-size: 20px;
  color: black;
  margin-left: -40px;
  margin-right: 80px;
  background: transparent;
  text-align: justify;
}

@media (max-width: 768px) { /* Adjusts for screens smaller than 768px */
  .flex-center {
    flex-direction: column; /* Stack items vertically on smaller screens */
  }
  
  .iphone-image {
    margin-left: 0; /* Adjust margin for mobile */
  }

  .text-container {
    margin-left: 10%; /* Adjust margin for mobile */
    margin-right: 10%; /* Adjust margin for mobile */
    margin-top: -30%;
    margin-bottom: 30%;
  }
}

.get-started-mobile {
  display: none;
}

/* Mobile styles */
@media (max-width: 768px) {
  .get-started-mobile {
    display: block; /* Show the button on mobile */
    margin-top: 20px; /* Adjust as needed */
    margin-bottom: 20px; /* Add space below the button */
    border: 2px solid white;
    color: rgba(40, 154, 166, 0.7);
    background-color: white;
    cursor: pointer;
    padding: 13px 20px;
    border-radius: 15px;
    font-weight: bold;
    text-align: center;
    font-size: 20px;
    width: auto; /* Adjust width as needed, or use 'auto' */
    /* Center the button */
    margin-left: auto;
    margin-right: auto;
    animation: pulse 2s infinite;
  }
}

/* Hide the container by default */
.mobile-text-container {
  display: none;
  color: transparent; /* Adjust the color as needed */
  padding: 15px;
  margin-top: 20px; /* Space above the container */
  text-align: center;
  border-radius: 5px;
}

.mobile-text-container1 {
  display: none;
  color: transparent; /* Adjust the color as needed */
  padding: 15px;
  margin-top: 15px; /* Space above the container */
  text-align: center;
  border-radius: 5px;
}
/* Mobile styles */
@media (max-width: 768px) {
  .mobile-text-container {
    display: block; /* Show the container on mobile */
    /* Additional styling here if needed */
  }
  
}

.get-started-mobile1 { /* Update this to match the class name in your JSX */
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 2px solid rgba(40, 154, 166, 0.7);
  color: rgba(40, 154, 166, 0.7);
  background-color: white;
  cursor: pointer;
  padding: 13px 20px;
  border-radius: 15px;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  animation: pulse 2s infinite;
}


.qa-text-box {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.qa-text-box input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 250px;
}

.qa-text-box button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: rgba(40, 154, 166, 1.0);
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.qa-answer {
  position: relative;
  margin-top: 5px;
  max-width: 300px;
  max-height: 30vh; /* Limit the height to 20% of the viewport height */
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: left; /* Left align the answer text */
  font-size: 14px; /* Adjust the font size as needed */
  overflow-y: auto; /* Add vertical scrollbar when content overflows */
}

.qa-answer::before {
  content: '';
  position: absolute;
  top: -10px;
  left: 20px;
  border: 10px solid transparent;
  border-bottom-color: white;
}

@media (max-width: 768px) {
  /* ... (previous mobile styles remain the same) */

  .qa-text-box {
    display: block;
    margin-top: 725px;
    margin-left: 7%;
    margin-right: 10%;
    width: 70%;
  }
  .qa-text-box input {
    padding: 5px;
    padding-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    width: 200px;
    
  }
  .qa-text-box button {
    margin-left: 10px;
    padding: 7px 10px;
    background-color: rgba(40, 154, 166, 1.0);
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  .mobile-text-container1 {
    display: block; /* Show the container on mobile */
    /* Additional styling here if needed */
  }
  
}

.spinner {
  animation: rotate 2s linear infinite;
  width: 20px;
  height: 10px;
}

.spinner .path {
  stroke: rgb(255, 255, 255);
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.desktop-only-image {
  display: none;
}

@media (min-width: 768px) {
  .desktop-only-image {
    display: block;
  }
}

.SP-div-style {
  width: 50%;
  margin: 20px auto;
  background: white;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  padding-bottom: 40px;
  color: grey;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

@media (max-width: 768px) {
  .SP-div-style {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .pricing-container {
      flex-direction: column !important;
  }

  .pricing-title {
    text-align: center;
    margin-top: 40px;
    padding-top: 200px;
    margin-bottom: 40px;
  }
}

.pricing-title {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.pricing-title-brand {
  font-family: 'Orbitron', sans-serif;
  color: white;

}

.pricing-title-text {
  font-weight: lighter;
  font-family: 'Arial', sans-serif;
  color: white;
}

@media (max-width: 768px) {
  .pricing-title {
    margin-top: 60px;
    margin-bottom: 20px;
  }
}

.get-started-mobile {
  display: none;
}

@media (max-width: 768px) {
  .get-started-mobile {
    display: block;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 2px solid rgba(40, 154, 166, 0.7);
  color: rgba(40, 154, 166, 0.7);
  background-color: white;
  cursor: pointer;
  padding: 13px 20px;
  border-radius: 15px;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  animation: pulse 2s infinite;
  }
}