.profile-container {
    display: flex;
    max-width: 1500px;
    margin: 0 auto;
  }
  
  .sidebar {
    width: 250px;
    border-right: 1px solid #ccc;
    padding: 20px;
    border-radius: 0;
  }
  
  .sidebar h3 {
    margin-bottom: 0px;
    letter-spacing: 3px;
  }
  
  .sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar li {
    padding: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.15);
    border-radius: 10px;
  }
  
  .sidebar li:hover {
    background-color: #f9f9f9;
  }
  
  .content {
    flex: 1;
    padding: 20px;
  }
  
  .user-info h1 {
    margin: 0;
    color: #333;
    font-size: 24px;
    letter-spacing: 3px;
  }
  
  .specialty {
    color: #666;
    margin-top: 5px;
  }
  
  .patient-details {
    margin-top: 30px;
  }
  
  .patient-details h2 {
    margin-top: 0;
    letter-spacing: 3px;
  }
  
  .edit-textarea {
    width: 100%;
    height: 150px; /* Adjust height as needed */
    margin-top: 10px;
    padding: 10px;
    font-size: 16px; /* Adjust font-size as needed */
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .save-btn {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 16px; /* Adjust font-size as needed */
    color: #fff;
    background-color: #000000; /* Adjust background-color as needed */
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .save-btn:hover {
    background-color: #0056b3; /* Darken button on hover */
  }

  .edit-textarea {
    width: 90%;
    height: 520px;
    margin-top: 10px;
    padding: 10px;
    font-size: 16px;
    border: 0px solid #ccc;
    border-radius: 4px;
    resize: vertical;
  }
  
  
  .save-btn:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .error {
    color: red;
    margin-top: 10px;
  }
  
  .patient-details-container {
    display: flex;
    justify-content: space-between;
  }
  
  .patient-details {
    flex: 2;
    margin-right: 20px;
  }
  
  .patient-insights {
    margin-top: 30px;
    flex: 1;
    text-align: left;
  }

  .title {
    text-align: center;
    letter-spacing: 3px;
  }

  
  .user-info input {
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 5px;
    font-size: 24px;
  }
  
  .user-info .fa-pencil-alt {
    margin-left: 10px;
    cursor: pointer;
    color: purple;
  }

  .container {
    border: 0px solid #ccc;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.15);
    height: 650px;
    background-color: white;
  }

  .container1 {
    border: 0px solid #ccc;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.15);
    height: 785px;
    background-color: white;
  }

  .container2 {
    border: 0px solid #ccc;
    border-radius: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.15);
    height: 190px;
    background-color: white;
  }

  .container3 {
    border: 0px solid #ccc;
    border-radius: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.15);
    height: 440px;
    background-color: white;
  }
  
  .sidebar ul {
    border: none;
    box-shadow: none;
  }

  .scrollable-container {
    overflow: auto;
    height: 440px; /* or any specific height */
  }

  .scrollable-container1 {
    overflow: auto;
  }

  a {
    text-decoration: none;
    font-size: 14px;
    color: #99a628;
  }